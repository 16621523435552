import React from "react"
import Layout from "../components/Layout"
import "../css/projects/projects.css"
import { Link } from "gatsby"
import Collapsible from "react-collapsible"
import { FaBoxOpen, FaHackerrank } from "react-icons/fa"
import { Helmet } from "react-helmet"
import Particle4 from "../components/Particle4"
import SEO from "../components/SEO"
import { FaHatWizard } from "react-icons/fa"

const Projects = () => {
  return (
    <Layout>
      <SEO
        title="Projects"
        description="This is the project Showcase page of Arpit Somani"
      />
      <Particle4></Particle4>
      <h6>s</h6>
      <Helmet>
        <script src="https://some.host/api.js" type="text/javascript" />
        <script src="https://code.iconify.design/1/1.0.7/iconify.min.js" />
      </Helmet>
      <div className="projects-heading">
        <h1>Projects</h1>
      </div>
      <aside className="sidebarr noSelect">
        <div className="special-box noSelect">
          <h4>Special Box</h4>
          <Collapsible title="special box" trigger={<FaBoxOpen></FaBoxOpen>}>
            <p className="p">
              Some Coding Platform I use ( After all compititive coding is
              impotant ! )
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.hackerrank.com/arpit_somani"
            >
              <h3 className="code-plat p1 noSelect">
                <FaHackerrank title="HackerRank"></FaHackerrank>
              </h3>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.hackerearth.com/@arpit.somanias8"
            >
              <div className="hackearth">
                <h3 title="HackerEarth" className="code-plat p2">
                  <div className="num1"></div>
                  <div className="num2">
                    <span
                      class="iconify"
                      data-icon="simple-icons:hackerearth"
                      data-inline="false"
                    ></span>
                  </div>
                </h3>
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.codechef.com/users/arpit_somani"
            >
              <h3 title="CodeChef" className="code-plat p3">
                <span
                  class="iconify"
                  data-icon="simple-icons:codechef"
                  data-inline="false"
                ></span>
              </h3>
            </a>
          </Collapsible>
        </div>
      </aside>

      <section className="project-page noSelect">
       
        <Link to="/dl/">
          <div className="button-6">
            <div class="eff-6"></div>
            <h2>Neural Net (DL)</h2>
          </div>
        </Link>
        <Link to="/nlp/">
          <div className="button-6">
            <div class="eff-6"></div>
            <h2>NLP</h2>
          </div>
        </Link>
        <Link to="/ml/">
          <div className="button-6">
            <div class="eff-6"></div>
            <h2>Machine Learning</h2>
          </div>
        </Link>
        <Link to="/data/">
          <div className="button-6">
            <div class="eff-6"></div>
            <h2>Data Analytics</h2>
          </div>
        </Link>
        <Link to="/pythonprojectcard/">
          <div className="button-6">
            <div class="eff-6"></div>
            <h2>Python</h2>
          </div>
        </Link>

        <Link to="/webprojectcard/">
          <div className="button-6">
            <div class="eff-6"></div>
            <h2>Web Development</h2>
          </div>
        </Link>

        <Link to="/dsa/">
          <div className="button-6">
            <div class="eff-6"></div>
            <h2>DSA (Basics) with Python</h2>
          </div>
        </Link>
        <div className="wizard">
          <FaHatWizard className="hatwizard"></FaHatWizard>
        </div>
        <Link>
          <div className="button-6 sdr">
            <div class="eff-6"></div>
            <h2>
              Coming soon !<h5 className="por">(Something Interesting)</h5>
            </h2>
          </div>
        </Link>
        <div className="hat">
          <div className="hat-desc">
            <h3>
              <span role="img" aria-label="Magic Spark">
                ✨
              </span>
              The Magic Box
              <span role="img" aria-label="Magic Ball">
                🔮
              </span>
            </h3>
            <p>
              Every one gets fantasize, whenever magic happens
              <br />
              So, here i have created a "Magic Box"
              <br />
              Yes, the one with the magical hat, and saying <br />
              Coming Soon (Something Intresting)
              <br />
              Well, it will remain as it is forever...
              <br />
              <br />
              As, it simply indicate my regular learning...
              <br />
              And i hope that this box remain as it is...
              <br />
              And release enormous amount of projects, knowledge and learnings
              throughout my life...
              <br />
              Well, the hint about what's coming next, is always somewhere in
              this website.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Projects
